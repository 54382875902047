import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { get, startCase } from 'lodash';
import './App.css';
import config from './config';

const DEV = false;

const corsFetch = async house => {
    const houseConfig = config[house];
    let url = DEV
        ? `http://localhost:3001/api/${house}`
        : `https://cors-anywhere.herokuapp.com/${houseConfig.baseUrl}`;

    if (houseConfig.isJSON) {
        url = `https://api.allorigins.win/get?url=${houseConfig.baseUrl}`;
    }

    console.log(`Fetching ${house}: ${url}`);
    return await fetch(url).then(r => (houseConfig.isJSON ? r.json() : r.text()));
};

const App = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        const getData = async () => {
            const data = [];

            const getAuctionsFromHtml = async key => {
                const houseConfig = config[key];
                const auctions = [];
                const html = await corsFetch(key);
                const domParser = new DOMParser();
                const $doc = domParser.parseFromString(html, 'text/html');
                const $items = $doc.querySelectorAll(houseConfig.selectors.item);

                $items.forEach(($item, i) => {
                    const title = $item.querySelector(houseConfig.selectors.itemTitle).textContent;
                    const dateEl = $item.querySelector(houseConfig.selectors.itemStartDate);
                    const date = dateEl ? dateEl.textContent.trim() : '';

                    const urlEl = $item.querySelector(houseConfig.selectors.itemLink);
                    const url = urlEl
                        ? `${houseConfig.linkPrepend}${urlEl.getAttribute('href')}`
                        : '';

                    auctions.push({ title, date, url });
                });
                return auctions;
            };

            const getAuctionsFromJson = async key => {
                const houseConfig = config[key];
                const auctions = [];
                const response = await corsFetch(key);
                const json = JSON.parse(response.contents);
                const items = get(json, houseConfig.selectors.item);

                for (let item of items) {
                    auctions.push({
                        title: get(item, houseConfig.selectors.itemTitle).replace(/<[^>]*>?/gm, ''),
                        date: get(item, houseConfig.selectors.itemStartDate),
                    });
                }
                return auctions;
            };

            for (let key in config) {
                setLoading(key);
                const auctions = config[key].isJSON
                    ? await getAuctionsFromJson(key)
                    : await getAuctionsFromHtml(key);
                setLoading(null);
                data.push({
                    house: key,
                    auctions,
                });
            }
            setData(data);
        };
        getData();
    }, []);

    return (
        <Root>
            <Houses count={Object.keys(data).length}>
                {loading && (
                    <Loading>
                        Loading <strong>{startCase(loading)}</strong>...
                    </Loading>
                )}
                {data &&
                    Object.keys(data).map(key => {
                        const item = data[key];
                        return (
                            <House>
                                <Title>{startCase(item.house)}</Title>
                                <Auctions>
                                    {item.auctions.map(a => (
                                        <Auction>
                                            <AuctionTitle>{a.title}</AuctionTitle>
                                            <AuctionStartDate>{a.date || ''}</AuctionStartDate>
                                            {a.url && (
                                                <AuctionLink>
                                                    <a href={a.url} target="_blank">
                                                        Link
                                                    </a>
                                                </AuctionLink>
                                            )}
                                        </Auction>
                                    ))}
                                </Auctions>
                            </House>
                        );
                    })}
            </Houses>
        </Root>
    );
};

const Root = styled.div``;

const House = styled.div`
    margin: 24px 0;
    padding: 0 12px;
`;
const Houses = styled.div`
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;

    ${House} {
        width: 20%;
        min-width: 200px;
    }
`;

const Title = styled.h3``;

const Auctions = styled.div``;

const Auction = styled.div`
    padding: 12px 0;
    border-top: 1px solid #ddd;
    font-size: 15px;
`;

const AuctionTitle = styled.p`
    margin: 0;
`;

const AuctionStartDate = styled.div`
    color: #666;
    margin-top: 0.3em;
    font-size: 0.9em;
`;

const AuctionLink = styled.div`
    margin-top: 0.3em;
    font-size: 0.8em;

    a {
        color: #3b60cb;
        text-decoration: none;
    }
`;

const Loading = styled.div`
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    margin: auto;
    height: 30px;
    line-height: 30px;
`;

export default App;
