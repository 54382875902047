export default {
    sothebys: {
        baseUrl: 'https://www.sothebysaustralia.com.au/auctions',
        linkPrepend: 'https://www.sothebysaustralia.com.au',
        selectors: {
            item: '.listItems .listItem',
            itemTitle: '.listItemDetails > a',
            itemStartDate: '.itemDate',
            itemLink: '.listItemDetails > a',
        },
    },
    leonardJoel: {
        baseUrl:
            'https://auctions.leonardjoel.com.au/custom_asp/schedule.asp?display_major_auctions=on&salenamesearch=',
        selectors: {
            item: '.auction-listing .auctions-calendar__listing',
            itemTitle: '.auction__details h2',
            itemStartDate: '.auction__more-detail',
            itemLink: '.listItemDetails > a',
        },
    },
    deutscherHackett: {
        baseUrl: 'https://www.deutscherandhackett.com/next-auctions',
        selectors: {
            item: '.content .field-name-field-auction-upcoming-text-past.field-type-text-long',
            itemTitle: 'p strong',
            itemStartDate: 'p:nth-child(2)',
            itemLink: '.foo',
        },
    },
    // lawsons: {
    //     baseUrl:
    //         'https://auctions.leonardjoel.com.au/custom_asp/schedule.asp?display_major_auctions=on&salenamesearch=',
    //     selectors: {
    //         item: '.auction-listing .auctions-calendar__listing',
    //         itemTitle: '.auction__details h2',
    //     },
    // },
    shapiro: {
        baseUrl: 'https://www.shapiro.com.au/auctions/upcoming-auctions/',
        selectors: {
            item: '.upcoming-holder',
            itemTitle: '.description h3',
            itemStartDate: '.description .col:first-child time',
            itemLink: '.listItemDetails > a',
        },
    },
    // bonhams: {
    //     isJSON: true,
    //     baseUrl:
    //         'https://www.bonhams.com/api/v1/search_json/?content=sale&date_range=future&division=SYD&length=12&page=1&randomise=False',
    //     selectors: {
    //         item: 'model_results.sale.items',
    //         itemTitle: 'name',
    //         itemStartDate: 'fmt_date',
    //         itemLink: '.listItemDetails > a',
    //     },
    // },
};
